@use "./colors";
@use "./fonts";
@use "./layout";

@mixin row-bottom-border {
  border-bottom: layout.$border-thin;
}

@mixin row-top-border {
  border-top: layout.$border-thin;
}

@mixin right-align {
  width: calc(
    100% - (layout.$spacing-medium-large + layout.$spacing-medium-small)
  );
  margin-left: layout.$spacing-medium-large;
}

@mixin appear {
  opacity: 1;
  transition: opacity 0.5s;
}

lim-ui-row-hover-buttons {
  opacity: 0;
}

.table-row-container:hover {
  transition: background-color 0.3s;
  background-color: var(--colors-level-two-active-background);
}

.table-row-container:hover .table-end-of-row-buttons {
  @include appear();
}

.custom-table-input-field:hover .delete-existing-field-button {
  @include appear();
}

.table-row-container {
  // We have to move the rows up to cover the previous row's border
  margin-top: calc(layout.$border-thin-size * -1);
}

.graph-header {
  // We give the header padding here since the lim-ui-card will not
  // have padding for tables.
  padding-left: layout.$spacing-medium-large;
  padding-right: layout.$spacing-medium-large;
}

.nowrap-col > .container-clipped {
  white-space: nowrap;
  @include layout.for-phone-only {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.number-column {
  display: inline-block;
  text-align: right;
  padding-right: layout.$spacing-medium-large;
}

.icon-col {
  display: inline-flex;
  column-gap: layout.$spacing-extra-small;
  flex-direction: row;
  color: var(--colors-secondary-text);
  align-items: center;
}

.listTable > .listItemHeader {
  margin-left: 0px;
  width: 100%;
  padding-left: layout.$spacing-medium-large;
  &.no-padding-header {
    padding-left: 0px;
  }
}

.listTable {
  // display: table;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: layout.$spacing-medium-large;
  @include fonts.fonts-body;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @include row-top-border();
  padding-top: layout.$spacing-extra-small;
  padding-bottom: layout.$spacing-extra-small;
  // word-break needed for long inputs in a table cell
  word-break: break-word;
  @include right-align();
  &.list-item-no-left-margin {
    margin-left: 0px;
  }
  &.last {
    @include row-bottom-border();
  }
}

.listItemHeader {
  width: 120%;
  color: var(--colors-secondary-text);
  @include row-bottom-border();
  @include row-top-border();
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: layout.$min-table-header-height;
  display: flex;
  align-items: center;
}

.modal-no-margin {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0 !important;
  padding-right: 0;
}

.scrollable-list-header {
  position: sticky;
  top: 0;
  background-color: var(--colors-level-one-background);
  z-index: 1;
}

// Needed to make this more specific.
.listItem.listItemHeader.scrollable-list-header {
  background-color: var(--colors-level-one-background);
}

// pseudo bootstrap grid: start
$colWidthSingle: calc(100 / 12 * 1%);
$gridColMax: 12;
@for $i from 1 through $gridColMax {
  .col-md-#{$i} {
    width: calc($colWidthSingle * $i);
    float: left;
  }
}
@for $i from 1 through $gridColMax {
  @include layout.for-phone-only {
    .col-sm-#{$i} {
      width: calc($colWidthSingle * $i);
    }
  }
}
// pseudo bootstrap grid: end

.table-column-badge-and-name-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table-column-badge {
  background-color: var(--colors-alert-warning-background);
  border: none;
  border-radius: 16px;
  display: inline-block;
  padding: layout.$spacing-small layout.$spacing-medium;
  margin-top: layout.$spacing-extra-small;
  white-space: normal;
  align-self: flex-start;
  word-break: break-word;
  cursor: pointer;
  @include fonts.fonts-body;
}

.list-view-name-field {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
}

.list-view-name-field-header {
  min-width: layout.$min-table-name-field-width;
  max-width: layout.$min-table-name-field-width;
}

.list-view-name-field {
  min-width: layout.$min-table-name-field-width;
  max-width: layout.$min-table-name-field-width;
}

.list-view-custom-columns-header {
  width: calc(100% - layout.$min-table-name-field-width);
}

.table-field-text {
  display: inline-block;
  word-break: break-word;
  height: auto;
  min-height: 32px;
  padding: 7px; // Needs to be 7px so the input remains at 32px height

  @include fonts.fonts-body;
}

.div-text-box {
  overflow-wrap: anywhere;
  word-break: normal;
  display: inline-block;
}

.table-input-field-editable {
  border: layout.$border-input;
  border-radius: layout.$border-radius-small;
  display: inline-block;
  word-break: break-word;
  background-color: var(--colors-input-background);
  min-width: 40px;

  @include fonts.fonts-body;
  & {
    color: var(--colors-general-text);
  }
}

div.table-input-field-editable {
  // If it is a content editable div, we need to force it to adjust with the column
  width: auto;
}

.table-input-field-editable:focus {
  outline: none;
  border: solid 1px var(--colors-input-border-focus);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
  box-shadow: 0 0 3px var(--colors-input-shadow-focus);
}

.custom-table-input-field {
  display: flex;
  gap: layout.$spacing-small;
  align-items: top;
}

.dark-icon {
  color: var(--colors-general-text);
}

.document-link-container {
  display: flex;
  gap: 4px;
  align-items: top;
  margin-top: layout.$spacing-small;
}

.document-link:hover {
  text-decoration: underline;
}

.delete-existing-field-button {
  opacity: 0;
  margin-left: auto;
  margin-right: layout.$spacing-small;
}

.document-link-container:hover .delete-document-link-button {
  @include appear();
}

.list-view-name-field-container,
.table-end-of-row-buttons {
  width: fit-content;
  min-height: 32px;
  display: flex;
  align-items: center;
}

.list-view-name-field-container {
  margin-right: layout.$spacing-small;
}

.table-end-of-row-buttons,
.custom-column-selector-button {
  min-width: 72px;
  // Align the hover buttons to the end of the row
  margin-left: auto;
  margin-right: layout.$spacing-medium-large;
}

.table-end-of-row-buttons {
  justify-content: flex-end;
}

.table-input-field-dropdown {
  display: flex;
  gap: layout.$spacing-extra-small;
  cursor: pointer;
}

.add-field-button {
  opacity: 0;
  cursor: pointer;
  color: var(--colors-success-text);
}

.adjustable-table-column:hover .add-field-button {
  @include appear();
}

.table-date-field {
  padding-top: 0px;
}

.vendor-purchase-orders-wrapper,
.table-image-wrapper,
.table-input-field-editable,
.document-link-container,
.add-field-button,
.table-date-field {
  margin-left: layout.$spacing-small;
}

.table-footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: layout.$spacing-small;
  align-items: center;

  @include layout.for-tablet-portrait-up {
    margin-top: layout.$spacing-medium;
    flex-direction: row;
  }
}

/*** Responsive styles ***/
.lim-ui-responsive-table {
  @include layout.for-tablet-and-smaller {
    @include row-top-border;
  }
}

.lim-ui-responsive-table-header {
  display: none;
  @include layout.for-desktop-up {
    display: flex;
  }
}

.lim-ui-responsive-table-row {
  flex-direction: column;
  @include layout.for-tablet-and-smaller {
    gap: layout.$spacing-small;
  }

  @include layout.for-desktop-up {
    flex-direction: row;
  }
}

.lim-ui-mobile-table-column-label {
  display: inline-block;
  margin-right: layout.$spacing-extra-small;
  @include layout.for-desktop-up {
    display: none;
  }
}

.lim-ui-responsive-table-cell {
  display: flex;

  // empty cells don't take up space in the table without a height
  min-height: fonts.$fonts-line-height-xs;

  @include layout.for-tablet-and-smaller {
    width: 100%;
    @include fonts.fonts-sub-title;
  }
  @include layout.for-desktop-up {
    display: inline-block;
  }
}

.lim-ui-mobile-table-header {
  @include layout.for-tablet-and-smaller {
    width: 100%;
  }
}

.lim-ui-responsive-table-row-wrapper {
  @include layout.for-tablet-and-smaller {
    display: grid;
    grid-template-columns: 90% 10%;
  }

  // Move the button menu to the correct place in the row
  .button-menu {
    position: relative;
    top: calc(layout.$spacing-medium * -1);
    left: calc(layout.$spacing-medium * -1);
  }
}
