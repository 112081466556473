@use "./colors";

body, // We add the light-theme CSS variables to the body tag as well so that any document using lim-ui can access all the css variables without needing to explicitly add the `light-theme` class. It essentially ensures that the light theme is the default.
.light-theme {
  // Layout/General
  --colors-page-background: #{colors.$colors-grey5};
  --colors-level-one-background: #{colors.$colors-white};
  --colors-level-two-background: #{colors.$colors-white};
  --colors-level-two-active-background: #{colors.$colors-grey10};
  --colors-level-three-background: #{colors.$colors-white};
  --colors-level-three-active-background: #{colors.$colors-grey10};
  --colors-level-four-background: #{colors.$colors-grey30};
  --colors-shadow-high: #{colors.$colors-shadow-high};
  --colors-shadow-low: #{colors.$colors-shadow-low};
  --colors-border-light: #{colors.$colors-grey10};
  --colors-border-heavy: #{colors.$colors-grey30};

  // Text/Icons
  --colors-white-text: #{colors.$colors-white};
  --colors-header-text: #{colors.$colors-limble-navy-blue};
  --colors-general-text: #{colors.$colors-grey};
  --colors-secondary-text: #{colors.$colors-grey80};
  --colors-tertiary-text: #{colors.$colors-grey30};
  --colors-success-text: #{colors.$colors-limble-green};
  --colors-danger-text: #{colors.$colors-limble-red};
  --colors-warning-text: #{colors.$colors-limble-orange};
  --colors-interactive-text: #{colors.$colors-limble-blue};

  // Inputs
  --colors-input-background: #{colors.$colors-white};
  --colors-input-border: #{colors.$colors-grey30};
  --colors-input-border-focus: #{colors.$colors-limble-green};
  --colors-input-shadow-focus: #{colors.$colors-limble-green20};

  // Buttons
  --colors-minimal-button-active: #{colors.$colors-grey30};
  --colors-minimal-button-hover: #{colors.$colors-grey10};
  --colors-dropdown-button-background: #{colors.$colors-grey10};
  --colors-dropdown-button-hover: #{colors.$colors-grey30};
  --colors-outlined-button-background: #{colors.$colors-white};
  --colors-outlined-button-hover: #{colors.$colors-grey10};
  --colors-outlined-button-active: #{colors.$colors-grey30};

  // Alerts
  --colors-alert-danger-background: #{colors.$colors-limble-red10};
  --colors-alert-warning-background: #{colors.$colors-limble-orange10};
  --colors-alert-success-background: #{colors.$colors-limble-green10};
  --colors-alert-info-background: #{colors.$colors-limble-blue10};

  // Badges
  --colors-badge-offline-background: #{colors.$colors-limble-red60};

  // Banners
  --colors-banner-primary: #{colors.$colors-limble-navy-blue};
  --colors-banner-success: #{colors.$colors-limble-green20};
  --colors-banner-error: #{colors.$colors-limble-red20};
  --colors-banner-warning: #{colors.$colors-limble-orange20};
  --colors-banner-info: #{colors.$colors-limble-blue20};
  --colors-banner-default: #{colors.$colors-white};

  --colors-success-button: #{colors.$colors-limble-green};
  --colors-error-button: #{colors.$colors-limble-red};
  --colors-warning-button: #{colors.$colors-limble-orange};
  --colors-info-button: #{colors.$colors-limble-blue};

  // Header
  --colors-header-secondary-background: #{colors.$colors-limble-green};

  // List Items
  --colors-list-item-background: #{colors.$colors-grey5};
  --colors-list-item-hover: #{colors.$colors-grey10};
  --colors-list-item-success-background: #{colors.$colors-limble-green10};
  --colors-list-item-danger-background: #{colors.$colors-limble-red10};

  // RGBA Background Colors
  --colors-list-item-background-half-opaque: rgba(242, 242, 242, 0.5);

  // Location Nav
  --colors-location-nav-background: #{colors.$colors-grey10};

  // Scroll Bar
  --colors-scroll-bar: #{colors.$colors-grey20};

  // Backdrop
  --colors-backdrop: #09182b66;

  // Full Calendar Color Overrides
  --fc-border-color: #{colors.$colors-grey20};

  // Tasks
  --colors-completed-task-background: #{colors.$colors-limble-green10};

  // Images
  --colors-image-placeholder-background: #{colors.$colors-grey10};
}

.dark-theme {
  // Layout/General
  --colors-page-background: #{colors.$colors-page-background-dark};
  --colors-level-one-background: #{colors.$colors-level-one-background-dark};
  --colors-level-two-background: #{colors.$colors-level-two-background-dark};
  --colors-level-two-active-background: #{colors.$colors-level-two-active-background-dark};
  --colors-level-three-background: #{colors.$colors-level-three-background-dark};
  --colors-level-three-active-background: #{colors.$colors-level-three-active-background-dark};
  --colors-level-four-background: #{colors.$colors-level-four-background-dark};
  --colors-shadow-high: #{colors.$colors-shadow-high-dark};
  --colors-shadow-low: #{colors.$colors-shadow-low-dark};
  --colors-border-light: #{colors.$colors-border-light-dark};
  --colors-border-heavy: #{colors.$colors-border-heavy-dark};

  // Text and Icons
  --colors-white-text: #{colors.$colors-white};
  --colors-header-text: #{colors.$colors-header-text-dark};
  --colors-general-text: #{colors.$colors-general-text-dark};
  --colors-secondary-text: #{colors.$colors-secondary-text-dark};
  --colors-tertiary-text: #{colors.$colors-tertiary-text-dark};
  --colors-success-text: #{colors.$colors-success-text-dark};
  --colors-danger-text: #{colors.$colors-danger-text-dark};
  --colors-warning-text: #{colors.$colors-warning-text-dark};
  --colors-interactive-text: #{colors.$colors-interactive-text-dark};

  // Inputs
  --colors-input-background: #{colors.$colors-page-background-dark};
  --colors-input-border: #{colors.$colors-border-heavy-dark};
  --colors-input-border-focus: #{colors.$colors-border-input-focus-dark};
  --colors-input-shadow-focus: #{colors.$colors-input-focus-shadow-dark};

  // Buttons
  --colors-minimal-button-active: #{colors.$colors-level-two-active-background-dark};
  --colors-minimal-button-hover: #{colors.$colors-border-light-dark};
  --colors-dropdown-button-background: #{colors.$colors-level-two-background-dark};
  --colors-dropdown-button-hover: #{colors.$colors-level-two-active-background-dark};
  --colors-outlined-button-background: #{colors.$colors-level-three-background-dark};
  --colors-outlined-button-hover: #{colors.$colors-level-three-active-background-dark};
  --colors-outlined-button-active: #{colors.$colors-level-four-background-dark};

  // Alerts
  --colors-alert-danger-background: #{colors.$colors-bg-dark-red};
  --colors-alert-warning-background: #{colors.$colors-bg-dark-orange};
  --colors-alert-success-background: #{colors.$colors-bg-dark-green};
  --colors-alert-info-background: #{colors.$colors-bg-dark-blue};

  // Badges
  --colors-badge-offline-background: #{colors.$colors-limble-red60};

  // Banners
  --colors-banner-primary: #{colors.$colors-limble-navy-blue};
  --colors-banner-success: #{colors.$colors-bg-dark-green};
  --colors-banner-error: #{colors.$colors-bg-dark-red};
  --colors-banner-warning: #{colors.$colors-bg-dark-orange};
  --colors-banner-info: #{colors.$colors-bg-dark-blue};
  --colors-banner-default: #{colors.$colors-grey};

  --colors-success-button: #{colors.$colors-limble-green};
  --colors-error-button: #{colors.$colors-limble-red};
  --colors-warning-button: #{colors.$colors-limble-orange};
  --colors-info-button: #{colors.$colors-limble-blue};

  // Header
  --colors-header-secondary-background: #{colors.$colors-limble-green};

  // List Items
  --colors-list-item-background: #{colors.$colors-level-three-background-dark};
  --colors-list-item-hover: #{colors.$colors-level-three-active-background-dark};
  --colors-list-item-success-background: #203b26;
  --colors-list-item-danger-background: #472a28;

  // RGBA Background Colors
  --colors-list-item-background-half-opaque: rgba(55, 64, 77, 0.5);

  // Location Nav
  --colors-location-nav-background: #{colors.$colors-border-light-dark};

  // Scroll Bar
  --colors-scroll-bar: #{colors.$colors-level-three-active-background-dark};

  // Backdrop
  --colors-backdrop: #2b313bd9;

  // Full Calendar Color Overrides
  --fc-border-color: #{colors.$colors-border-heavy-dark};

  // Tasks
  --colors-completed-task-background: #203b26;

  // Images
  --colors-image-placeholder-background: #{colors.$colors-border-light-dark};
}
