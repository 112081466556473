@use "./colors";
@use "./fonts";
@use "./layout";

// The contenteditable attribute gets added by the limbleContentEditable directive.
[limbleContentEditable],
[contenteditable="true"],
textarea,
input {
  border: layout.$border-input;
  border-radius: layout.$border-radius-medium;
  box-sizing: border-box;
  padding: layout.$spacing-medium;
  max-width: layout.$form-input-max-width;
  min-height: 50px;
  width: 100%;
  background-color: var(--colors-input-background);
  @include fonts.fonts-body;

  .disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 50%;
    &:focus {
      outline: none;
      border: layout.$border-thin;
    }
  }
  &:focus {
    outline: none;
    border: solid 1px var(--colors-input-border-focus);
    box-shadow: 0 0 0 2px var(--colors-input-shadow-focus);
  }

  &.table-field-text {
    padding-left: layout.$spacing-small;
  }

  &::placeholder {
    font-style: italic;
    color: var(--colors-secondary-text);
  }

  &[type="number"] {
    padding-right: layout.$spacing-extra-small;
  }

  &.has-error:not(:focus) {
    border: solid 1px var(--colors-danger-text);
  }
}

input.required + span:after,
textarea.required + span:after {
  content: "*";
  font-size: 16px;
  color: var(--colors-danger-text);
  margin-top: 6px;
  position: absolute;
  right: 11px;
  top: -1px;
}

@include layout.for-tablet-portrait-up {
  // The contenteditable attribute gets added by the limbleContentEditable directive.
  [limbleContentEditable],
  [contenteditable="true"],
  textarea,
  input {
    padding: layout.$spacing-horizontal-medium;
    min-height: 32px;
    line-height: layout.$spacing-medium;
  }

  // The contenteditable attribute gets added by the limbleContentEditable directive.
  [limbleContentEditable],
  [contenteditable="true"] {
    padding-top: layout.$spacing-small;
    padding-bottom: layout.$spacing-small;
    &.required::after {
      content: "*";
      font-size: 16px;
      color: var(--colors-danger-text);
      float: right;
      margin-top: 6px;
      margin-right: 7px;
    }
    &.medium-text-field {
      min-height: 100px;
      min-width: 100%;
      padding-top: layout.$spacing-small;
    }
    &.large-text-field {
      min-height: 150px;
      min-width: 100%;
      padding-top: layout.$spacing-small;
    }
  }
}

.lim-ui-input-inline-text-small {
  width: 60px;
  height: 24px;
  min-height: 24px;
  padding-left: layout.$spacing-small;
  border-radius: layout.$border-radius-small;
}

.lim-ui-input-inline-text-medium {
  width: 150px;
  height: 24px;
  min-height: 24px;
  padding-left: layout.$spacing-small;
  border-radius: layout.$border-radius-small;
}

.lim-ui-input-inline-text-large {
  width: 250px;
  height: 24px;
  min-height: 24px;
  padding-left: layout.$spacing-small;
  border-radius: layout.$border-radius-small;
}

::placeholder {
  color: var(--colors-tertiary-text);
}

:-ms-input-placeholder {
  color: var(--colors-tertiary-text);
}

::-ms-placeholder {
  color: var(--colors-tertiary-text);
  opacity: 1;
}

.red-border {
  cursor: pointer;
  text-decoration: none;
  border: solid 1px colors.$colors-youtube-red;
}

.filter-input-box {
  width: 160px;
}

.filter-inputs-container {
  /** Align the labels and inputs on a smaller screen **/
  display: grid;
  grid-template-columns: 26% 74%;
  align-items: center;
  grid-template-rows: auto;
  gap: layout.$spacing-small;

  @include layout.for-tablet-portrait-up {
    display: flex;
    flex-wrap: wrap;
  }
}

.list-search-container {
  margin-bottom: layout.$spacing-medium;
}

.lim-ui-form-column {
  display: flex;
  flex-direction: column;
  row-gap: layout.$spacing-medium;
  .lim-ui-form-group {
    display: flex;
    flex-direction: column;
    row-gap: layout.$spacing-extra-small;

    label {
      display: block;
      @include fonts.fonts-body-semi-bold;
    }

    a {
      text-decoration: none;
      cursor: pointer;
      color: var(--colors-interactive-text);
      &:hover {
        color: var(--colors-interactive-text);
      }
    }
  }
}

.lim-ui-input-icon-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  span:first-child {
    left: layout.$spacing-small;
    position: absolute;
  }

  input:first-child {
    padding-right: 20px;
  }

  span:last-child {
    right: layout.$spacing-small;
    position: absolute;
  }

  input:last-child {
    padding-left: 20px;
  }
}

[contenteditable] {
  &[placeholder]:empty:before {
    content: attr(placeholder);
    font-style: italic;
    color: var(--colors-tertiary-text);
  }
}
