@use "./colors";

// Paddings
$spacing-extra-small: 4px;
$spacing-small: 8px;
$spacing-medium-small: 12px;
$spacing-medium: 16px;
$spacing-medium-large: 24px;
$spacing-large: 32px;
$spacing-extra-large: 40px;

// Horizontal Paddings
$spacing-horizontal-extra-small: 0 $spacing-extra-small;
$spacing-horizontal-small: 0 $spacing-small;
$spacing-horizontal-medium: 0 $spacing-medium;
$spacing-horizontal-medium-large: 0 $spacing-medium-large;
$spacing-horizontal-large: 0 $spacing-large;
$spacing-horizontal-extra-large: 0 $spacing-extra-large;

// Vertical Paddings
$spacing-vertical-extra-small: $spacing-extra-small 0;
$spacing-vertical-small: $spacing-small 0;
$spacing-vertical-medium: $spacing-medium 0;
$spacing-vertical-medium-large: $spacing-medium-large 0;
$spacing-vertical-large: $spacing-large 0;
$spacing-vertical-extra-large: $spacing-extra-large 0;

// Shadows
$shadow-high: 0px 12px 16px var(--colors-shadow-high);
$shadow-low: 0px 2px 4px var(--colors-shadow-low);

// Borders
$border-thin-size: 1px;
$border-main-size: 2px;
$border-thick-size: 4px;
$border-thin: $border-thin-size solid var(--colors-border-light);
$border-input: $border-thin-size solid var(--colors-input-border);
$border-main: $border-main-size solid var(--colors-border-light);
$border-thick: $border-thick-size solid var(--colors-border-light);

// Border Radiuses
$border-radius-extra-small: 2px;
$border-radius-small: 4px;
$border-radius-medium: 6px;
$border-radius-medium-large: 8px;
$border-radius-large: 12px;
$border-radius-extra-large: 16px;
$border-radius-pill-shape: 50rem;

//scroll bar
$scrollbar-small: 4px;
$scrollbar-medium: 8px;

// Forms
$form-input-max-width: 424px;

// Tables
$min-table-header-height: 42px;
$min-table-name-field-width: 250px;

// Page layout variables
$page-header-height: 120px;

// Print view
$field-height-in-print-view: 18px;

// Flex Layouts
@mixin flex-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin overflow-scrollbar-hidden {
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

@mixin flex-col-3 {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-medium-large;

  & > * {
    flex: 1 1 calc(33.333% - 18px);
    min-width: 190px;

    @include for-tablet-landscape-up() {
      flex-grow: 0;
    }
  }
}

@mixin flex-col-4 {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-medium-large;

  & > * {
    flex: 1 1 calc(25% - 18px);
    min-width: 190px;

    @include for-tablet-landscape-up() {
      flex-grow: 0;
    }
  }
}

/*
* Screen size mixins
* Usage: @include layout.for-tablet-portrait-up {margin-left: -0.1em;}
*/
@mixin for-phone-only {
  @media (max-width: 749px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 750px) {
    @content;
  }
}

@mixin for-tablet-portrait-and-smaller {
  @media (max-width: 908px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 908px) {
    @content;
  }
}

@mixin for-tablet-and-smaller {
  @media (max-width: 1169px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1170px) {
    @content;
  }
}

@mixin for-mobile-landscape-down {
  @media (max-height: 749px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-gridster-portrait-and-smaller {
  @media (max-width: 895px) {
    @content;
  }
}

.hover-item {
  opacity: 0;
}

.hover-container:hover .hover-item {
  opacity: 1;
  transition: 0.5s;
}

.control-label .required-symbol,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: var(--colors-danger-text);
}

lim-ui-panel.has-error.form-control,
.has-error .form-control {
  border: 1px solid colors.$colors-limble-red;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

lim-ui-panel.has-error.form-control {
  border-radius: $spacing-small;
  @include for-phone-only {
    border-radius: 0;
  }
}

.has-error .form-control:focus {
  border-color: colors.$colors-limble-red;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 6px colors.$colors-limble-red;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 6px colors.$colors-limble-red;
}
