@use "lim-ui";

//the container for the legend
.chart-js-legend-container {
   @include lim-ui.flex-center-content;
   font: normal normal bold 14px/16px Clarity City;

   &.print {
      display: block;
      font-weight: normal;
      margin-left: 32px;
   }
}

.chart-js-line-or-bar-legend {
   display: flex;
   flex-wrap: wrap;
}

.chart-js-pie-legend {
   display: grid;
   grid-gap: 14px 70px;
   margin-top: 40px;

   &.print {
      margin-top: unset;
      row-gap: 8px;
   }
}

//items within the composed legend
.chart-js-legend-item-container {
   min-width: 150px;
   max-width: 150px;

   &.print {
      max-width: none;
      width: 100%;
   }
}

.chart-js-legend-item-text-container {
   display: flex;
   flex-direction: column;

   &.print {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 6px 4px;
   }
}

.chart-js-pie-legend-item-label {
   display: flex;
   align-items: center;

   &.print {
      font-weight: bold;
   }
}

.chart-js-line-or-bar-legend-item-label {
   min-width: 150px;
   max-width: 150px;
   display: flex;
   font-weight: normal;
   color: #727374;
}

.chart-js-legend-bullet {
   width: 14px;
   min-width: 14px;
   height: 14px;
   border-radius: 50%;
   margin-right: 8px;
}

.chart-js-legend-sub-item-bullet {
   border-radius: 50%;
   height: 4px;
   width: 4px;
}

// sub-items within an individual item
.chart-js-legend-sub-item-container {
   margin-left: 22px;
   margin-top: 8px;

   &.print {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 4px;
      margin-left: 0;
      margin-top: 0;
   }
}

.chart-js-legend-sub-item-text {
   font-weight: normal;
   color: var(--colors-general-text);
}

.chart-js-legend-sub-tooltip-item-text {
   font-weight: normal;
   color: var(--colors-white-text);
}
