@use "./colors";
@use "./layout";

/*
 * The leading '-' char here indicates that this variable is private.
 * See: https://sass-lang.com/documentation/at-rules/use#private-members
 */
$-fontstack: "Clarity City", sans-serif;

/*
 * Weights.
 * These should be used sparingly. Favor using a typography mixin rather
 * than using these directly.
 */
$fonts-weight-medium: 400;
$fonts-weight-semi-bold: 500;
$fonts-weight-semi-bolder: 600;
$fonts-weight-extra-bold: 700;

/*
 * Line Heights.
 * These should be used sparingly. Favor using a typography mixin rather
 * than using these directly.
 */
$fonts-line-height-xl: 32px;
$fonts-line-height-lg: 24px;
$fonts-line-height-md: 18px;
$fonts-line-height-sm: 16px;
$fonts-line-height-xs: 13px;

$print-view-small-font-size: 10px;
/*
 * Typography Mixins and Classes.
 * Prefer using the mixin to write semantic classes, rather than using the
 * typography classes directly.
 */

@mixin fonts-h1 {
  font-family: $-fontstack;
  font-weight: $fonts-weight-extra-bold;
  font-size: 24px;
  line-height: $fonts-line-height-xl;
  color: var(--colors-header-text);

  @include layout.for-phone-only {
    font-size: 20px;
    line-height: $fonts-line-height-lg;
  }
}
.lim-ui-fonts-h1 {
  @include fonts-h1;
}

@mixin fonts-h2 {
  font-family: $-fontstack;
  font-size: 20px;
  line-height: $fonts-line-height-lg;
  font-weight: $fonts-weight-semi-bold;
  color: var(--colors-header-text);

  @include layout.for-phone-only {
    font-size: 18px;
    line-height: $fonts-line-height-md;
  }
}
.lim-ui-fonts-h2 {
  @include fonts-h2;
}

@mixin fonts-h2-extra-bold {
  @include fonts-h2;
  font-weight: $fonts-weight-extra-bold;
}

@mixin fonts-h3 {
  font-family: $-fontstack;
  font-weight: $fonts-weight-semi-bold;
  font-size: 14px;
  line-height: $fonts-line-height-md;
  color: var(--colors-general-text);

  @include layout.for-phone-only {
    font-size: 16px;
    line-height: $fonts-line-height-md;
  }
}

@mixin fonts-small-table-text {
  font-family: $-fontstack;
  font-size: 14px;
  line-height: $fonts-line-height-sm;
  font-weight: $fonts-weight-medium;
  color: var(--colors-general-text);
}

@mixin fonts-body {
  font-family: $-fontstack;
  font-weight: $fonts-weight-medium;
  font-size: 14px;
  line-height: $fonts-line-height-md;
  color: var(--colors-general-text);

  @include layout.for-phone-only {
    font-size: 16px;
  }
}
.lim-ui-fonts-body {
  @include fonts-body;
}

@mixin fonts-body-semi-bold {
  @include fonts-body;
  & {
    font-weight: $fonts-weight-semi-bold;
  }
}
.lim-ui-fonts-body-semi-bold {
  @include fonts-body-semi-bold;
}

@mixin fonts-body-semi-bolder {
  @include fonts-body;
  & {
    font-weight: $fonts-weight-semi-bolder;
  }
}
.lim-ui-fonts-body-semi-bolder {
  @include fonts-body-semi-bolder;
}

@mixin fonts-body-extra-bold {
  @include fonts-body;
  & {
    font-weight: $fonts-weight-extra-bold;
  }
}
.lim-ui-fonts-body-extra-bold {
  @include fonts-body-extra-bold;
}

@mixin fonts-caption {
  font-family: $-fontstack;
  font-weight: $fonts-weight-medium;
  font-size: 12px;
  line-height: $fonts-line-height-sm;
  color: var(--colors-general-text);

  @include layout.for-phone-only {
    font-size: 14px;
    line-height: $fonts-line-height-sm;
  }
}
.lim-ui-fonts-caption {
  @include fonts-caption;
}

@mixin fonts-table-sub-title {
  @include fonts-sub-title;
  @include layout.for-desktop-up {
    font-size: 14px;
    color: var(--colors-success-text);
  }
}

@mixin fonts-table-title {
  font-family: $-fontstack;
  font-size: 16px;
  line-height: $fonts-line-height-md;
  font-weight: $fonts-weight-extra-bold;
  color: var(--colors-general-text);

  @include layout.for-desktop-up {
    @include fonts-small-table-text;
    & {
      color: var(--colors-interactive-text);
    }
  }
}

@mixin fonts-sub-title {
  @include fonts-body;
  & {
    color: var(--colors-secondary-text);
  }
}
.lim-ui-fonts-sub-title {
  @include fonts-sub-title;
}

@mixin fonts-big-numbers {
  font-family: $-fontstack;
  font-size: 24px;
  line-height: $fonts-line-height-xl;
  font-weight: $fonts-weight-extra-bold;
  color: var(--colors-header-text);

  @include layout.for-phone-only {
    font-size: 20px;
    line-height: $fonts-line-height-lg;
  }
}
.lim-ui-fonts-big-numbers {
  @include fonts-big-numbers;
}

@mixin fonts-badge {
  font-family: $-fontstack;
  font-weight: $fonts-weight-semi-bold;
  font-size: 11px; //the font-size is 11px instead of 12px because 12px creates crowding for 4-digit #s on badges
  line-height: $fonts-line-height-xs;
  color: var(--colors-white-text);
}

@mixin fonts-table-column {
  font-family: $-fontstack;
  font-size: 14px;
  line-height: $fonts-line-height-md;
  font-weight: $fonts-weight-medium;
  color: var(--colors-general-text);
}

@mixin fonts-fine-print {
  font-family: $-fontstack;
  font-size: 10px;
  line-height: $fonts-line-height-xs;
  font-weight: $fonts-weight-medium;
}
